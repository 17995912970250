import Cookies from "js-cookie";
import { SumSubAPI } from "../api/sumsub-api";
import {
  SET_ACCESS_TOKEN_ERROR,
  SYNC_WITH_BACK_ERROR,
} from "../reducers/types";
import {
  setErrorAC,
  setLoadingAC,
  setAccessTokenAC,
  updateProfileWithSumsubDataAC,
} from "./actions";
import { utilityFns } from "../ui/utilities/utility-functions";

export const sumsubThunks = {
  getAccessTokenTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await SumSubAPI.getAccessToken();
      if (response.data.success) {
        dispatch(setAccessTokenAC(response.data.token));
      }
      dispatch(setErrorAC({ type: SET_ACCESS_TOKEN_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SET_ACCESS_TOKEN_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  syncronizeInfoWithBack: (isSimulator) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await SumSubAPI.syncBack();
      if (response.data.success) {
        if (isSimulator) {
          const domain =
            window?.location.hostname === "dev.onboarding.zimtra.at-hub.com"
              ? ".zimtra.at-hub.com"
              : ".zimtra.ky";
          Cookies.remove("onboardingToken", {
            domain,
            secure: true,
            sameSite: "None",
          });
          Cookies.set("portalToken", response.data.portalToken, {
            expires: 30,
            domain,
            secure: true,
            sameSite: "None",
          });
          utilityFns.redirectToPortal();
        } else {
          const newProfile = response.data.registration;
          const neededData = {
            title: newProfile.title,
            firstName: newProfile.firstName,
            middleName: newProfile.middleName || null,
            lastName: newProfile.lastName,
            dateofbirth: newProfile.dateofbirth,
            citizenship: newProfile.citizenship,
            pep: newProfile.pep,
            pepcomment: newProfile.pepcomment || null,
            phone: newProfile.phone,
            street: newProfile.street,
            street2: newProfile.street2,
            city: newProfile.city,
            zip: newProfile.zip,
            country: newProfile.country,
          };
          dispatch(updateProfileWithSumsubDataAC(neededData));
        }
      }
      dispatch(setErrorAC({ type: SYNC_WITH_BACK_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: SYNC_WITH_BACK_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
